<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="reviseKpoCardForm"
        ref="reviseKpoCardForm"
        class="custom-form pt-8"
        @submit.prevent="submitReviseKpoCardForm"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="debrisTypes"
              label="Typ odpadu"
              v-model="revision.debrisTypeId"
              placeholder="Typ odpadu"
              item-value="id"
              outlined
              dense
              :rules="[rules.required]"
              :item-text="getDebrisString"
              :filter="searchDebrisType"
            />
          </v-col>

          <v-col
            cols="2"
            class="pr-0"
          >
            <v-checkbox
              v-model="revision.wasteCodeExtended"
              class="mt-0 font-weight-bold"
              label="Kod ex"
              hide-details
            />
          </v-col>

          <v-col cols="10">
            <v-text-field
              v-model="revision.wasteCodeExtendedDescription"
              outlined
              label="Rodzaj odpadu"
              class="pl-2"
              placeholder="Rodzaj odpadu"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="revision.revisedWeight"
              outlined
              type="number"
              label="Masa odpadów [T]"
              :rules="[rules.required]"
              placeholder="Masa odpadów [T]"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="reviseKpoCardForm"
      >
        Wprowadź korektę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import { searchDebrisType, getDebrisString } from '../../utils'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      revision: {
        debrisTypeId: null,
        revisedWeight: null,
        wasteCodeExtended: false,
        wasteCodeExtendedDescription: null,
      },
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.client.isProcessing,
      debrisTypes: state => state.debrisTypes.items,
      bdoCard: state => state.layout.dialog.item,
    }),
  },
  created () {
    const {
      wasteCodeExtended, wasteCodeExtendedDescription, reportedWeight, reportedDebrisType, revisedWeight, revisedDebrisType
    } = this.bdoCard
    if (wasteCodeExtended) this.revision.wasteCodeExtended = wasteCodeExtended
    if (wasteCodeExtendedDescription) this.revision.wasteCodeExtendedDescription = wasteCodeExtendedDescription
    if (reportedWeight || revisedWeight) this.revision.revisedWeight = revisedWeight || reportedWeight
    if (reportedDebrisType || revisedDebrisType) this.revision.debrisTypeId = revisedDebrisType.id || reportedDebrisType.id
    this.getDebrisTypes(({ params: { aggregate: false } }))
  },
  methods: {
    ...mapActions({
      getDebrisTypes: 'debrisTypes/getItems',
      reviseBdoCard: 'bdoCard/reviseBdoCard',
      closeDialog: 'layout/closeDialog',
    }),
    searchDebrisType,
    getDebrisString,
    submitReviseKpoCardForm () {
      if (this.$refs.reviseKpoCardForm.validate()) {
        this.reviseBdoCard(this.revision)
          .then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
